// important to load this first
import 'reflect-metadata';
import './environments/environment';
// important to load this first
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';

import { App } from './app/app';
import { envValue } from 'app-zephyr-environment';

if (envValue.value.useSentry) {
  Sentry.init({
    dsn: envValue.value.sentryDsn,
    environment: envValue.value.env,
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration({ maskAllText: false })],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    release: envValue.value.sentryRelease,
    ignoreErrors: [
      // This error is thrown when link scanners (mainly Outlook 365) tries to scan our links
      /^Non-Error promise rejection captured with value: Object Not Found Matching Id:/i
    ]
  });
}

const container = document.getElementById('root') ?? document.createElement('div');

createRoot(container).render(
  <StrictMode>
    <App />
  </StrictMode>,
);
