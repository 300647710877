import { ReactElement, ReactNode } from 'react';
// @mui
import { Box, Modal, Typography, IconButton } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
// components
import { Button } from 'app-zephyr-components/Button';
import { ErrorIcon, WarningIcon } from 'app-zephyr-icons';
// styles
import { useStyles } from './styles';

export type ConfirmationModalType = 'warning' | 'error';

export interface ConfirmationModalProps {
  /**
   * modal title
   */
  title: string;
  /**
   * optional modal description
   */
  description?: string;
  /**
   * optional custom modal icon
   */
  icon?: React.ReactElement;
  /**
   * modal type
   */
  type: ConfirmationModalType;
  /**
   * modal open state
   */
  open: boolean;
  /**
   * optional cancel button text
   */
  cancelBtnText?: string;
  /**
   * optional confirm button text
   */
  confirmBtnText?: string;
  /**
   * optional modal content for description
   */
  children?: ReactNode | ReactElement;
  /**
   * optional disabled state for confirm button
   */
  disabled?: boolean;
  /**
   * extra styling for the modal
   */
  extraClasses?: string;
  /**
   * close handler
   */
  onClose: () => void;
  /**
   * confirm handler
   */
  onConfirm: () => void | Promise<void>;
}
/**
 * Confirmation modal component with warning and error types.
 */
export const ConfirmModal = ({
  title,
  description,
  icon,
  type,
  open,
  cancelBtnText = 'No, go back',
  confirmBtnText = 'Confirm',
  children,
  disabled,
  extraClasses,
  onClose,
  onConfirm,
}: ConfirmationModalProps) => {
  const { classes, cx, theme } = useStyles();

  const handleClose = () => {
    onClose();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      disableEnforceFocus
    >
      <Box className={cx(classes.modal, type, extraClasses, icon ? 'customIcon' : '')}>
        <div className={classes.header}>
          <span className="icon">{icon ?? <WarningIcon />}</span>
          <h3>{title}</h3>
          <IconButton className={classes.close} disableRipple onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </div>
        {(description ?? children) && (
          <Box>
            {description && (
              <Typography className={classes.description} variant="body1">
                {description}
              </Typography>
            )}

            {children && <div className={classes.content}>{children}</div>}
          </Box>
        )}
        <Box className={classes.footer}>
          <Button variant="outlined" onClick={handleClose} data-e2e="CONFIRMATION_MODAL__CANCEL_BUTTON">
            {cancelBtnText}
          </Button>
          <Button
            variant="contained"
            color={type}
            type="submit"
            data-e2e="CONFIRMATION_MODAL__CONFIRM_BUTTON"
            disabled={disabled}
            onClick={onConfirm}
          >
            <ErrorIcon color={disabled ? theme.palette.tx.disabled : theme.palette.tx[type].primary} />
            <span className={classes.confirmBtnTitle}>{confirmBtnText}</span>
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
