import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { FormInput } from 'app-zephyr-forms';
import { joiResolver } from '@hookform/resolvers/joi';

import { renameFormSchema } from 'ze-api-contract/application-v2/application-environment/create';

export type RenameApplicationEnvironmentFormFields = { name: string } & Record<string, string>;

interface RenameApplicationEnvironmentFormProps {
  name: string;
  setFormValid: (value: boolean) => void;
  onChangeValue: (name: string) => void;
}

const RenameApplicationEnvironmentForm = ({
  name,
  setFormValid,
  onChangeValue,
}: RenameApplicationEnvironmentFormProps) => {
  const {
    register,
    getValues,
    watch,
    formState: { errors, isValid, isDirty },
  } = useForm<RenameApplicationEnvironmentFormFields>({
    defaultValues: { name },
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: joiResolver(renameFormSchema),
  });

  const nameWatch = watch('name');

  useEffect(() => {
    const nameValue = getValues().name;
    setFormValid(isValid && isDirty);
    onChangeValue(nameValue);
  }, [nameWatch]);

  return (
    <form
      onChange={() => {
        onChangeValue(getValues().name);
      }}
    >
      <FormInput id="rename-field" name={'name'} register={register} errors={errors} />
    </form>
  );
};

export { RenameApplicationEnvironmentForm };
