import { Link } from '@tanstack/react-router';

import { makeStyles } from 'tss-react/mui';

import { ErrorCard } from 'app-zephyr-components/ErrorCard';
import { DangerIcon } from 'app-zephyr-icons/Danger';
import { LightHorizontalWithLogoImg } from 'app-zephyr-components/LightHorizontalWithLogoImg';

import { RouteNames } from 'app-zephyr-routes';

const useStyles = makeStyles()((theme) => ({
  page: {
    display: 'flex',
    flexDirection: 'column',
  },
  navbar: {
    padding: '32px',
    display: 'flex',
    alignItems: 'center',
  },
  line: {
    width: '100%',
    flex: '1',
    height: '1px',
    marginLeft: '32px',
    border: 'none',
    borderBottom: theme.palette.border.secondary,
  },
  container: {
    display: 'flex',
    width: '100%',
    height: '80vh',
    justifyContent: 'center',
    alignItems: 'center',
  },
  paper: {
    display: 'block',
    textAlign: 'center',
  },
  logoLink: {
    cursor: 'pointer',
  },
}));

export default function ForbiddenError() {
  const { classes } = useStyles();
  const axiosErrorMessage = sessionStorage.getItem('axiosErrorMessage');
  const axiosError = sessionStorage.getItem('axiosError');

  return (
    <div className={classes.page}>
      <div className={classes.navbar}>
        <Link to={RouteNames.PERSONAL_DASHBOARD} search={(p: never) => p} params={(p: never) => p}>
          <LightHorizontalWithLogoImg class={classes.logoLink} />
        </Link>
        <hr className={classes.line} />
      </div>
      <div className={classes.container}>
        <ErrorCard
          icon={DangerIcon}
          title="403"
          subtitle={axiosErrorMessage ?? 'Access denied'}
          description={axiosError ?? 'This page require special permission'}
          image={{ url: '/svg/vectors/access-denied.svg', alt: 'Access Denied Illustration' }}
        />
      </div>
    </div>
  );
}
