import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    fontFamily: 'Inter',

    '& .error-message': {
      color: theme.palette.tx.error.primary,
      fontSize: '12px',
      margin: 0,
    },
    '& .value-error-message': {
      marginLeft: 120,
    },
  },

  textInput: {
    input: {
      paddingTop: 10,
      paddingBottom: 10,
    },

    fieldset: {
      display: 'none',
    },
  },

  searchSelect: {
    '&.MuiAutocomplete-root': {
      width: '100%',

      fieldset: {
        display: 'none',
      },

      '.MuiInputBase-root': {
        paddingTop: '5px',
        paddingBottom: '2px',
        marginTop: 0,
      },
    },

    '.MuiSvgIcon-root': {
      fill: theme.palette.brand.turquoise[500],
    },

    '.MuiFormLabel-root': {
      top: '-5px',
    },
  },

  typeSelect: {
    '&.MuiInputBase-root': {
      background: theme.palette.bg.primary.default,
      marginTop: 0,
      color: theme.palette.tx.secondary.default,

      '.MuiSelect-select': {
        minWidth: 65,
      },

      '&:first-child': {
        fontWeight: 700,
        background: theme.palette.bg.primary.default,
        border: theme.palette.border.secondary,
        borderRadius: '8px 0 0 8px',
        width: 'fit-content',
        maxWidth: '30%',
      },

      '&:nth-child(2)': {
        borderRadius: '0 8px 8px 0;',
        width: '100%',
      },

      '.MuiOutlinedInput-notchedOutline': {
        border: 'none',
      },

      '.MuiSelect-icon': {
        fill: theme.palette.brand.turquoise[500],
      },

      '&.Mui-disabled': {
        color: theme.palette.tx.placeholder.default,
        '&:hover': {
          backgroundColor: 'transparent',
          cursor: 'default',
        },
      },
    },
  },

  selectContainer: {
    display: 'flex',
    border: theme.palette.border.secondary,
    borderRadius: 9,
    width: '100%',
    height: 45,
  },
  label: {
    fontSize: 14,
    fontWeight: 700,
    color: theme.palette.tx.primary,
  },
  placeholder: {
    color: theme.palette.tx.placeholder.default,
  },
}));
