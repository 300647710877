export enum Channel {
  Branch = 'branch',
  Version = 'version',
  User = 'user',
  isCI = 'isCI',
  Target = 'target',
}

export const channelList = [Channel.Version, Channel.Branch, Channel.User, Channel.isCI, Channel.Target];

export const ChannelTooltips = {
  target: 'This field is not editable because the value is generated based on local build output.',
  version: `Input a specific version you need for this tag - if your build output URL is 'https://name-123-branch.domain.com' then the version is 'name-123-branch'`,
  branch: 'Input a specific branch you need for this tag. This field is case sensitive.',
  user: 'Start typing to search for a user.',
  isCI: 'Pin this tag to attach any build output from CI servers.',
} as Record<Channel, string>;

export interface ConditionValue {
  value: string;
  label: string;
}

export interface Condition {
  type: Channel;
  value: ConditionValue;
}

export interface SearchCondition {
  type: Channel;
  value: string;
}

export interface ApplicationTag {
  id: string;
  name: string;
  applicationVersionId: string;
  version?: {
    id: string;
    name: string;
    remote_host: string | null;
    remote_entry_url: string | null;
  };
  conditions: Condition[];
  publishedAt?: string;
  downloads?: number;
  createdAt: string;
  updatedAt?: string;
  deletedAt?: string;
  remote_host: string;
  remote_entry_url: string;
}

export interface ApplicationTagUIdParams {
  organization: string;
  project: string;
  application: string;
  tag: string;
}

export interface Error {
  message: string;
  response: {
    data: {
      errorMessage: string;
    };
  };
}
