// TODO: deprecated
import type { WithoutTier, WithTier } from 'app-zephyr-components/AddonSliderControl';
import { envValue } from 'app-zephyr-environment';
import { Metrics, PlanType } from 'ze-api-contract/enums';

export const environment = envValue.value;

export interface PlanConfiguration {
  stripeId: string;
  features: string[];
}

export type AddonsQuota = Record<Metrics, number>;

export const PLAN_ADDONS_NAMES_MAP: Record<Metrics, string> = {
  [Metrics.users]: 'Users',
};

export const PLANS_ADDONS_QUOTA_PRESETS: Record<PlanType, AddonsQuota> = {
  [PlanType.personal]: {
    [Metrics.users]: 0,
  },
  [PlanType.standard]: {
    [Metrics.users]: 1,
  },
  [PlanType.team]: {
    [Metrics.users]: 0,
  },
};

type AddonSliderControlDTO = {
  stripeId: string;
  title: string;
  tooltip?: string;
  slideRange: [min: number, max: number];
} & (WithTier | WithoutTier);

export const ADDONS_GLOBAL_SLIDER_CONTROL_CONFIGURATIONS: Record<Metrics, AddonSliderControlDTO> = {
  [Metrics.users]: {
    stripeId: environment.stripePrices.users,
    title: 'Users',
    // tooltip: 'Something about Builds',
    step: 1,
    price: {
      amount: 49.0,
    },
    slideRange: [0, 100],
  },
};
