import * as Joi from 'joi';
import {
  APPLICATION_CONDITION_FIELD_JOI_VALIDATOR_MESSAGES,
  APPLICATION_ENVIRONMENT_NAME_JOI_VALIDATOR_MESSAGES,
  APPLICATION_ENVIRONMENT_NAME_VALIDATOR,
  validationErrorMessagesDescriptionField,
  APPLICATION_DNS_FIELD_VALIDATION_REGEXP,
  APPLICATION_DNS_FIELD_JOI_VALIDATION_MESSAGES,
} from '../../validation';
import { channelList } from './interfaces';

export const environmentNameSchema = Joi.string()
  .pattern(APPLICATION_ENVIRONMENT_NAME_VALIDATOR.pattern.value)
  .min(APPLICATION_ENVIRONMENT_NAME_VALIDATOR.minLength.value)
  .max(APPLICATION_ENVIRONMENT_NAME_VALIDATOR.maxLength.value)
  .required()
  .messages(APPLICATION_ENVIRONMENT_NAME_JOI_VALIDATOR_MESSAGES);

export const baseSchema = Joi.object({
  name: environmentNameSchema,
  description: Joi.string().empty('').max(200).messages(validationErrorMessagesDescriptionField),
});

export const renameSchema = Joi.object({
  name: environmentNameSchema,
});

export const conditionValueSchema = Joi.object({
  label: Joi.string().required().messages(APPLICATION_CONDITION_FIELD_JOI_VALIDATOR_MESSAGES),
  value: Joi.string().required().messages(APPLICATION_CONDITION_FIELD_JOI_VALIDATOR_MESSAGES),
});

export const channelTypeSchema = Joi.string()
  .valid(...channelList)
  .required()
  .messages({ enum: `Channel type must be one of ${channelList.join(', ')}` });

export const baseApplicationVersionId = Joi.string()
  .required()
  .messages({ guid: 'Application version id must be a valid UUID v4' });

export const baseCustomDNSNames = Joi.array()
  .optional()
  .items(Joi.string().required())
  .empty(Joi.array().length(0))
  .messages(APPLICATION_CONDITION_FIELD_JOI_VALIDATOR_MESSAGES);

export const formApplicationVersionId = Joi.string()
  .required()
  .messages(APPLICATION_CONDITION_FIELD_JOI_VALIDATOR_MESSAGES);

export const formSchemaName = Joi.string().required().messages(APPLICATION_CONDITION_FIELD_JOI_VALIDATOR_MESSAGES);

export const formSchemaCondition = Joi.object({
  type: Joi.object({
    label: Joi.string(),
    value: channelTypeSchema,
  }),
  value: conditionValueSchema,
});

export const formCustomDnsNames = Joi.string()
  .empty('')
  .max(APPLICATION_DNS_FIELD_VALIDATION_REGEXP.maxLength)
  .min(APPLICATION_DNS_FIELD_VALIDATION_REGEXP.minLength)
  .pattern(APPLICATION_DNS_FIELD_VALIDATION_REGEXP.pattern)
  .messages(APPLICATION_DNS_FIELD_JOI_VALIDATION_MESSAGES);
