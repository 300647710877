import { simplePath } from 'ze-api-contract/utils';
import { Channel, ConditionValue } from './interfaces';

export const GET_APPLICATION_TAG_CONDITION_OPTIONS =
  '/v2/application/:organization/:project/:application/tag/condition/options';

interface GetApplicationTagConditionsParams {
  organization: string;
  project: string;
  application: string;
}

export function get_application_tag_conditions_path(params: GetApplicationTagConditionsParams): string {
  return simplePath(GET_APPLICATION_TAG_CONDITION_OPTIONS, params);
}

export interface ApplicationTagConditionOptions {
  [Channel.Version]: ConditionValue[];
  [Channel.Branch]: ConditionValue[];
  [Channel.User]: ConditionValue[];
  [Channel.isCI]: ConditionValue[];
  [Channel.Target]: ConditionValue[];
}

export interface ConditionItem {
  label: string;
  value: string;
}
